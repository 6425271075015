/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  Funcionalidade,
  HistoricoUsuario,
  Orgao,
  Perfil,
  PerfilFuncionalidade,
  PerfilVisualizacao,
  TermoDeUso,
  TipoOrgao,
  Usuario,
  UsuarioOrgaoPerfil,
} from "../core/model";
import { Notificacao, NotificacaoRequestBody } from "../admin-v2/data-access/notificacoes.model";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  adminUrl = `${environment.apiUrl}admin`;
  funcionalidadesUrl = `${this.adminUrl}/funcionalidade`;
  tipoOrgaoUrl = `${this.adminUrl}/tipoorgao`;
  perfilUrl = `${this.adminUrl}/perfil`;
  orgaosUrl = `${this.adminUrl}/orgao`;
  usuariosUrl = `${this.adminUrl}/usuario`;
  municipiosUrl = `${environment.apiUrl}municipio`;
  areaDeAtuacaoUrl = `${this.adminUrl}/areadeatuacao`;
  areaDeAtuacaoSolicitacaoUrl = `${this.adminUrl}/areadeatuacaosolicitacaousuario`;
  passagemUrl = `${environment.apiUrl}v2/passagem`;
  limitesTempoUrl = `${this.passagemUrl}/limites`;

  constructor(private http: HttpClient) {}

  getOrgaos(): Observable<any> {
    return this.http
      .get<Orgao[]>(`${this.orgaosUrl}`)
      .pipe(map((resp) => resp.map((tipo) => this.convertOrgao(tipo))));
  }

  getOrgaoById(id: number): Observable<any> {
    return this.http.get(`${this.orgaosUrl}/${id}`);
  }

  saveOrgao(orgao: Orgao): Observable<any> {
    return orgao.id
      ? this.http.put(`${this.orgaosUrl}/${orgao.id}`, orgao)
      : this.http.post(`${this.orgaosUrl}`, orgao);
  }

  getFuncionalidades(): Observable<any> {
    return this.http.get(`${this.funcionalidadesUrl}`);
  }

  getFuncionalidadeById(id: number): Observable<any> {
    return this.http.get(`${this.funcionalidadesUrl}/${id}`);
  }

  saveFuncionalidade(funcionalidade: Funcionalidade): Observable<any> {
    return funcionalidade.id
      ? this.http.put(
          `${this.funcionalidadesUrl}/${funcionalidade.id}`,
          funcionalidade,
        )
      : this.http.post(`${this.funcionalidadesUrl}`, funcionalidade);
  }

  saveTipoOrgao(tipoOrgao: TipoOrgao): Observable<any> {
    return tipoOrgao.id
      ? this.http.put(`${this.tipoOrgaoUrl}/${tipoOrgao.id}`, tipoOrgao)
      : this.http.post(`${this.tipoOrgaoUrl}`, tipoOrgao);
  }

  savePerfil(perfil: Perfil): Observable<any> {
    return perfil.id
      ? this.http.put(`${this.perfilUrl}/${perfil.id}`, perfil)
      : this.http.post(`${this.perfilUrl}`, perfil);
  }

  removePerfil(perfil: Perfil): Observable<any> {
    return this.http.delete(
      `${this.perfilUrl}/${perfil.id}?forcarExclusao=true`,
    );
  }

  getPerfisByOrgaoId(id: number): Observable<any> {
    return this.http.get(`${this.perfilUrl}/orgao/${id}`);
  }

  getPerfisByTipoOrgaoId(id: number): Observable<any> {
    return this.http.get(`${this.perfilUrl}/tipoorgao/${id}`);
  }

  getUsuarioPerfisByUsuarioId(id: number): Observable<any> {
    return this.http.get(`${this.usuariosUrl}/perfil/${id}`);
  }

  getTipoOrgaos(): Observable<any> {
    return this.http.get<TipoOrgao[]>(`${this.tipoOrgaoUrl}`);
  }

  getTipoOrgaosComPerfis(): Observable<any> {
    return this.http
      .get<TipoOrgao[]>(`${this.tipoOrgaoUrl}`)
      .pipe(map((resp) => resp.map((tipo) => this.convertTipoOrgao(tipo))));
  }

  getTipoOrgaoById(id: number): Observable<any> {
    return this.http.get(`${this.tipoOrgaoUrl}/${id}`);
  }

  getFuncionalidadesByPerfilId(id: number): Observable<any> {
    return this.http.get(`${this.funcionalidadesUrl}/perfil/${id}`);
  }

  addPerfilFuncionalidade(
    perfilFuncionalidade: PerfilFuncionalidade,
  ): Observable<any> {
    return this.http.post(
      `${this.funcionalidadesUrl}/perfil`,
      perfilFuncionalidade,
    );
  }

  removePerfilFuncionalidade(
    idPerfil: number,
    idFuncionalidade: number,
  ): Observable<any> {
    return this.http.delete(
      `${this.funcionalidadesUrl}/perfil/${idPerfil}/${idFuncionalidade}`,
    );
  }

  addPerfilVisualizacaoPorLocal(
    idPerfil: number,
    valor: string,
  ): Observable<any> {
    return this.http.post(
      `${this.adminUrl}/perfilVisualizacaoPorLocal/${idPerfil}/${valor}`,
      null,
    );
  }

  addPerfilVisualizacaoPorOrgao(
    idPerfil: number,
    valor: string,
  ): Observable<any> {
    return this.http.post(
      `${this.adminUrl}/perfilVisualizacaoPorOrgao/${idPerfil}/${valor}`,
      null,
    );
  }

  setPerfilVisualizacao(
    perfilVisualizacao: PerfilVisualizacao,
  ): Observable<any> {
    return this.http.put(
      `${this.adminUrl}/perfilVisualizacao/${perfilVisualizacao.id}`,
      perfilVisualizacao,
    );
  }

  removePerfilVisualizacao(idPerfilVisualizacao: number): Observable<any> {
    return this.http.delete(
      `${this.adminUrl}/perfilVisualizacao/${idPerfilVisualizacao}`,
    );
  }

  getPerfilById(id: number): Observable<any> {
    return this.http.get(`${this.adminUrl}/perfil/${id}`);
  }

  getMunicipiosByTermo(termo: string): Observable<any> {
    return this.http.get(`${this.municipiosUrl}/filtrar/${termo}`);
  }

  getLocaisByTermo(termo: string): Observable<any> {
    return this.http.get(`${this.adminUrl}/local/${termo}`);
  }

  getOrgaosByTermo(termo: string): Observable<any> {
    return this.http.get(`${this.adminUrl}/orgao/termo/${termo}`);
  }

  convertTipoOrgao(tipo): TipoOrgao {
    if (tipo.perfis)
      tipo.perfis = tipo.perfis.map((p) => p.descricao).join(", ");
    return tipo;
  }

  convertOrgao(orgao): Orgao {
    orgao.municipio = orgao.municipio.descricao + "/" + orgao.municipio.ufSigla;
    orgao.tipo = orgao.tipo.descricao;
    orgao.circunscricao =
      orgao.circunscricao === "N"
        ? "Nacional"
        : orgao.circunscricao === "E"
          ? "Estadual"
          : "Municipal";

    return orgao;
  }

  getUsuarios(
    filter = "",
    pageNumber = 0,
    pageSize = 5,
    orderBy = "nomeCompleto",
  ): Observable<any> {
    const params = new HttpParams()
      .set("page", pageNumber.toString())
      .set("size", pageSize.toString());
    return this.http.get(`${this.usuariosUrl}?orderBy=${orderBy}${filter}`, {
      params,
    });
  }

  getTermoVigente(): Observable<any> {
    return this.http.get(`${this.adminUrl}/termoVigente`);
  }

  saveTermoVigente(novoTermoVigente: TermoDeUso): Observable<any> {
    return this.http.post(`${this.adminUrl}/termoVigente`, novoTermoVigente);
  }

  aceitarTermoVigente(idTermo: number) {
    return this.http.put(`${this.adminUrl}/aceitarTermo/${idTermo}`, null);
  }

  getUsuarioById(id: number, status: string): Observable<any> {
    return this.http.get(`${this.usuariosUrl}/${id}/${status}`);
  }

  getUsuarioByCpf(cpf: string): Observable<any> {
    return this.http.get(`${this.usuariosUrl}/${cpf}`);
  }

  getSolicitacaoByCpf(cpf: string): Observable<any> {
    return this.http.get(`${this.usuariosUrl}/solicitacao/${cpf}`);
  }

  saveUsuario(usuario: Usuario, status: string): Observable<any> {
    return this.http.put(
      `${this.usuariosUrl}/${usuario.id}/${status}`,
      usuario,
    );
  }

  // saveSolicitacaoCadastro(solicitacaoCadastro: SolicitacaoCadastro): Observable<any> {
  //   return this.http.put(`${this.usuariosUrl}/solicitacao/${solicitacaoCadastro.id}`, solicitacaoCadastro) ;
  // }

  removeUsuarioPerfil(usuarioPerfil: UsuarioOrgaoPerfil): Observable<any> {
    return this.http.delete(`${this.usuariosUrl}/perfil/${usuarioPerfil.id}`);
  }

  addUsuarioPerfil(usuarioPerfil: UsuarioOrgaoPerfil): Observable<any> {
    return this.http.post(`${this.usuariosUrl}/perfil`, usuarioPerfil);
  }

  getHistoricoUsuarioByCpf(cpf: string): Observable<any> {
    return this.http.get(`${this.usuariosUrl}/historico/${cpf}`);
  }

  addHistoricoUsuario(historicoUsuario: HistoricoUsuario): Observable<any> {
    return this.http.post(`${this.usuariosUrl}/historico`, historicoUsuario);
  }

  addValidacao(
    solicitacaoId: number,
    tipo: string,
    opcao: string,
  ): Observable<any> {
    return this.http.put(
      `${this.usuariosUrl}/validacao/${solicitacaoId}/${tipo}`,
      opcao,
    );
  }

  getValidacoes(solicitacaoId: number): Observable<any> {
    return this.http.get(`${this.usuariosUrl}/validacao/${solicitacaoId}`);
  }

  getAreasDeAtuacao(): Observable<any> {
    return this.http.get(this.areaDeAtuacaoUrl);
  }

  getAreaDeAtuacaoById(id: number): Observable<any> {
    return this.http.get(`${this.areaDeAtuacaoUrl}/${id}`);
  }

  getAreasDeAtuacaoSolicitacaoUsuariooByIdSolicitacao(
    id: number,
  ): Observable<any> {
    return this.http.get(`${this.areaDeAtuacaoSolicitacaoUrl}/${id}`);
  }

  removeSolicitacaoUsuarioById(id: number): Observable<any> {
    return this.http.delete(`${this.areaDeAtuacaoSolicitacaoUrl}/${id}`);
  }

  getLimitesTemporais(): Observable<any[]> {
    return this.http.get<number[]>(this.limitesTempoUrl);
  }

  postNotificacao(notificacao: NotificacaoRequestBody): Observable<any> {
    return this.http.post(`${this.adminUrl}/notificacao/salvareenviar`, notificacao);
  }

  alterarNotificacao(notificacao: Notificacao): Observable<any> {
    return this.http.post(`${this.adminUrl}/notificacao/alterar`, notificacao);
  }

}
